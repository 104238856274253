// set basepath of project for webpack
import { basePath } from './core/config'
__webpack_public_path__ = `${basePath}/assets/frontend/js/`

import 'console-polyfill'
import 'custom-event-polyfill'
import elementClosestPolyfill from 'element-closest'
elementClosestPolyfill(window)

import './core/Sentry'

import $ from 'jquery'

// jquery utils
import 'jquery.easing'

import './plugins/nette.ajax'
import './plugins/nette.ajax.history'
import './plugins/giant.trigger'
import './plugins/giant.autosubmit'

// other
import './plugins/flickity.ios13fix.js'

// nette
import './nette/NetteValidationMessages'
import './nette/NetteSnippets'
import './nette/NetteEvents'
import './nette/NetteLoading'

import Nette from './nette/NetteForms'
window.Nette = Nette
Nette.initOnLoad()


if ($.nette) {
    $.nette.init()
}

import { android, iOS, IE, CMS } from './core/config'

import TouchMouseInputResolver from './meta/TouchMouseInputResolver'
new TouchMouseInputResolver()

import ScrollService from './services/Scroll'
if (IE || android || iOS || CMS || (typeof DISABLE_SMOOTH_SCROLL !== 'undefined' && DISABLE_SMOOTH_SCROLL) || document.body.classList.contains('u-nativeScroll')) {
    ScrollService.useNative()
    setScrollPosition()
} else {
    ScrollService.useVirtual()
        .catch(() => ScrollService.useNative())
        .finally(() => setScrollPosition())
}


import { loadComponents, removeComponents } from './core/componentUtils'
loadComponents()

/**
 * lazy loading
 */
import lazySizes from 'lazysizes'
lazySizes.cfg.lazyClass = 'is-lazy'
lazySizes.cfg.loadingClass = 'is-lazy-loading'
lazySizes.cfg.loadedClass = 'is-lazy-loaded'


import Swup from 'swup'
import SwupGtmPlugin from '@swup/gtm-plugin'
import SwupBodyClassPlugin from '@swup/body-class-plugin'
import { queryAll } from './utils/dom'
import EventBus from './core/EventBus'
import { EVENTS } from './core/Events'
import { createScriptTag, runScriptsInsideElement } from './utils/script'


function setScrollPosition(x = null, y = null) {
    const id = window.location.hash.replace('#', '')
    const anchor = id ? document.getElementById(id) : null

    if (id && anchor) {
        const animate = ScrollService.isVirtual ? false : true
        ScrollService.scrollToElement(anchor, { animate: animate, mode: 'auto' })
    } else if (x !== null && y !== null) {
        ScrollService.setPosition(x, y)
    } else {
        //default chovani prohlizece
    }
}

if (!CMS) {
    const swup = new Swup({
        linkSelector: [
            'a[href^="' + window.location.origin + '"]:not([data-no-swup]):not([data-component]):not([target="_blank"]):not(.ajax)',
            'a[href^="/"]:not([data-no-swup]):not([data-component]):not([target="_blank"]):not(.ajax)',
            // 'a[href^="#"]:not([data-no-swup]):not([data-component]):not(.ajax)'
        ].join(', '),
        // animationSelector: '',
        plugins: [new SwupBodyClassPlugin(), new SwupGtmPlugin()],
        containers: ['#swup', '#footer', '#link-tag', '#modals', '#fixed-header-button', '#header-lang', '#sojern'],
        skipPopStateHandling: event => {
            if (event?.state?.source === 'swup') {
                return false
            }
            return true
        }
    })

    swup.on('clickLink', event => {
        EventBus.emit(EVENTS.SWUP_CLICK_LINK, { event })
    })

    swup.on('willReplaceContent', event => {
        EventBus.emit(EVENTS.SWUP_WILL_REPLACE_CONTENT)

        queryAll('[data-swup]').forEach(element => {
            removeComponents(element)
        })
    })

    swup.on('contentReplaced', event => {
        Nette.reinit()

        if (android || IE || iOS || CMS || (typeof DISABLE_SMOOTH_SCROLL !== 'undefined' && DISABLE_SMOOTH_SCROLL) || document.body.classList.contains('u-nativeScroll')) {
            ScrollService.useNative()
        } else {
            ScrollService.useVirtual()
                .catch(() => ScrollService.useNative())
        }


        queryAll('[data-swup]').forEach(element => {
            loadComponents(element);

            //na bookine a voucherpage poustet scripty rovnou a necekat na komponenty
            if (document.body.classList.contains('booking-Page') || document.body.classList.contains('voucher-Page')) {
                runScriptsInsideElement(element)
            }

            //poustet sojern scripty po swupu na kazdy strance
            const sojernEl = document.getElementById('sojern')
            if (sojernEl) {
                runScriptsInsideElement(element)
            }
        })

        if ($.nette) {
            $.nette.load()
        }

        setTimeout(() => {
            ScrollService.resize()
            setScrollPosition(0, 0)
        }, 0)
    })
}
